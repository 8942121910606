import React from "react";
import TitleAndDesc from "../components/TitleAndDescription";
import Layout from "../components/Layout";
import Fact from "../components/Fact";

import { useGetAllFactsQuery } from "../hooks/FactADay/useGetAllFactsQuery";
//   This is the main Fact a day page
// It uses the useGetAllFactsQuery hook to get all the facts but the query limits the number of posts per page to 15

const FAD = () => {
	const allFactsData = useGetAllFactsQuery();
	console.log(allFactsData);

	const allFactsArray = allFactsData.allWpFactADay.edges;
	console.log("array of facts", allFactsArray);

	return (
		<Layout>
			<div>The search will go here</div>
			<br></br>
			<TitleAndDesc />
			<div>
				{allFactsArray.map((fact) => {
					return (
						<Fact
							key={fact.node.id}
							title={fact.node.title}
							fact={fact.node.content}
							date={fact.node.date}
						/>
					);
				})}
			</div>
		</Layout>
	);
};

export default FAD;
