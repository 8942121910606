import { useStaticQuery, graphql } from "gatsby";

export const useGetAllFactsQuery = () => {
	const data = useStaticQuery(graphql`
		query {
			allWpFactADay {
				edges {
					node {
						date
						id
						uri
						title
						content
					}
				}
			}
		}
	`);
	return data;
};
