import React from "react";

//  This is the Fact component. It sits in the FactGroup component as a child

const Fact = ({ title, fact, date, source1, source2, source3 }) => {
	// category will need to be added above for the grouping

	// if the last 2 sources are empty then we just display the first source
	if (source2 == null && source3 == null) {
		return (
			<div>
				<h1>{title}</h1>
				<h2>{date}</h2>
				<p>{fact}</p>
				<div>
					<button>
						<a href={source1}>Source 1</a>
					</button>
				</div>
				<br></br>
			</div>
		);
		// if the last source is  empty then we just display the first 2 sources
	} else if (source3 == null) {
		return (
			<div>
				<h1>{title}</h1>
				<h2>{date}</h2>
				<p>{fact}</p>
				<div>
					<button>
						<a href={source1}>Source 1</a>
					</button>
				</div>
				<div>
					<button>
						<a href={source2}>Source 2</a>
					</button>
				</div>
				<br></br>
			</div>
		);
		// if all the sources are included we display all three
	} else {
		return (
			<div>
				<h1>{title}</h1>
				<h2>{date}</h2>
				<p>{fact}</p>
				<div>
					<button>
						<a href={source1}>Source 1</a>
					</button>
				</div>
				<div>
					<button>
						<a href={source2}>Source 2</a>
					</button>
				</div>
				<div>
					<button>
						<a href={source3}>Source 3</a>
					</button>
				</div>
				<br></br>
			</div>
		);
	}
};

export default Fact;
